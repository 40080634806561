import React from 'react';
import Modal from '../../../components/Modal';
import img from '../../../images/city/houston_header.jpg';
import graphic from '../../../images/graphics/modal-houston.jpg';
import report from '../../../report/report.pdf';
const media = {
  secTitle: 'Houston, TX',
  cityModal: true,
  type: 'image',
  image: {
    src: img,
    alt: 'Houston, TX',
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      With a population of 2.3 million people, Houston has been described as the
      most racially and ethnically diverse major metropolis in the country. The
      city’s economy has a broad industrial base in energy, manufacturing,
      aeronautics and transportation. Houston has the second-most Fortune 500
      headquarters of any U.S. city. Houston is a city where the population is
      more than 50 percent people of color: 43.9 percent Latino, 22.7 percent
      black and 25.5 percent white.
    </p>
    <p>
      Over the last two decades, Houston has experienced a 10 percent decline in
      its white population while its Latino population has grown over 25
      percent. There are an estimated 600,000 undocumented immigrants in the
      Houston area, comprising nearly 9 percent of the city’s metropolitan
      population. The median hourly wage for white workers is $30 per hour while
      people of color only earn $15 per hour. Today, 37 percent of its jobs
      require some college education. While 65 percent of white workers meet
      this requirement, only 27 percent of black workers and 25 percent of
      Latino workers have the necessary education to compete. In Houston, we
      held three focus groups composed of white, black and Latino residents all
      under the age of 35. Learn more by reading the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>
    <img
      src={graphic}
      alt="Bar charts about racial distribution in Houston, TX"
      className="Img-responsive"
    />
  </Modal>
);
